export default function () {
  const useCustomFetch = (api, opts) => {
    return useFetch(api, {
      method: opts.method,
      body: opts.body,
      onRequest: (res) => {
        onRequestBase(res)
        opts && opts.onRequest && opts.onRequest(res)
      },
      onRequestError: (resError) => {
        onRequestErrorBase(resError)
        opts && opts.onRequestError && opts.onRequestError(resError)
      },
      onResponse: (res) => {
        onResponseBase(res)
        opts && opts.onResponse && opts.onResponse(res)
      },
      onResponseError: (resError) => {
        onResponseErrorBase(resError)
        opts && opts.onResponseError && opts.onResponseError(resError)
      }
    })
  }

  // 設定請求時夾帶的標頭
  const onRequestBase = ({ request, response, options }) => {
    const token = localStorage.getItem('token')
    const tenantId = localStorage.getItem('tenantId')
    options.headers = options.headers || {}
    options.headers.token = token
    options.headers.Tenant = tenantId
    options.headers['Content-Type'] = 'application/json'
  }

  // 處理請求時發生的錯誤
  const onRequestErrorBase = ({ request, response, options }) => {
    if (!response.ok) {
      switch (response.status) {
        case 401:
          alert('登入過期' + response.statusText)
          break
        case 403:
          alert('沒有權限' + response.statusText)
          break
        case 404:
          alert('頁面不存在' + response.statusText)
          break
        case 500:
          alert('程式發生問題' + response.statusText)
          break
        case 504:
          alert('請求超時' + response.statusText)
          break
        default:
          alert('error' + response.statusText)
          break
      }
    }
  }

  // 處理請求回應的資料
  const onResponseBase = ({ request, response, options }) => {
    const res = response._data
    if (res.code) {
      switch (res.code) {
        case 200:
          return res.data
        case 3002:
          navigateTo('/login')
          break
        default :
          ElMessage.error({ message: res.msg })
          break
      }
    }
  }

  // 處理請求回應發生的錯誤
  const onResponseErrorBase = ({ request, response, options }) => {
    if (!response.ok) {
      switch (response.status) {
        case 401:
          // alert('登入過期')
          navigateTo('/login')
          break
        case 403:
          alert('沒有權限')
          break
        case 404:
          alert('頁面不存在')
          break
        case 500:
          alert('程式發生問題')
          break
        case 504:
          alert('請求超時')
          break
        default:
          alert('error' + response.statusText)
          break
      }
    }
  }

  return {
    useCustomFetch
  }
}
