
export const toQueryString = (params) => {
  const queryParams = new URLSearchParams()

  for (const [key, value] of Object.entries(params)) {
    if (value !== '' && value !== undefined && value !== null) {
      queryParams.append(key, value)
    }
  }

  return queryParams.toString()
}

export const toQueryStringWithArray = (params) => {
  const queryParams = new URLSearchParams()

  for (const [key, value] of Object.entries(params)) {
    if (value !== '' && value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryParams.append(key, item)
        })
      } else {
        queryParams.append(key, value)
      }
    }
  }

  return queryParams.toString()
}

export const toQueryStringWithObject = (params) => {
  const queryParams = new URLSearchParams()

  for (const [key, value] of Object.entries(params)) {
    if (value !== '' && value !== undefined && value !== null) {
      if (typeof value === 'object') {
        for (const [k, v] of Object.entries(value)) {
          queryParams.append(key + '.' + k, v)
        }
      } else {
        queryParams.append(key, value)
      }
    }
  }

  return queryParams.toString()
}

export const toQueryStringWithObjectArray = (params) => {
  const queryParams = new URLSearchParams()

  for (const [key, value] of Object.entries(params)) {
    if (value !== '' && value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          for (const [k, v] of Object.entries(item)) {
            queryParams.append(key + '.' + k, v)
          }
        })
      } else {
        queryParams.append(key, value)
      }
    }
  }

  return queryParams.toString()
}

export const getApi = (base, module, path) => {
  return base + module + path
}
/**
 * 組合url和query參數
 * @param {String} path url
 * @param {Object} queryParams query參數
 * @returns URL
 */
export const combineUrlWithParams = (path, queryParams) => {
  if (!queryParams) {
    return path
  }

  return path + '?' + toQueryString(queryParams)
}

/**
 * 字串格式化
 * @param {String} format
 * @param  {...any} args
 * @returns
 */
export const stringFormat = (format, ...args) => {
  return format.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined' ? String(args[number]) : match
  })
}

export const isEmpty = (value) => {
  return value === '' || value === null || value === undefined
}

export const isNotEmpty = (value) => {
  return !isEmpty(value)
}

export const getPathName = (url) => {
  try {
    // 創建一個 URL 對象
    const urlObject = new URL(url)

    // 返回 pathname，這就是我們需要的路由部分
    return urlObject.pathname
  } catch (error) {
    // 如果 URL 無效，返回錯誤信息
    console.error('Invalid URL:', error)
    return null
  }
}
